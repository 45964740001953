<template>
    <v-container fluid grid-list-md>
      <v-layout>
        <v-flex md12>
          <v-data-table
            :headers="headers"
            :items="getDiscounts"
            :search="search"
            sort-by="id"
            class="elevation-1"
          >
            <template v-slot:item.procent ="{ item }">
              <p>{{ item.procent }}%</p>
            </template>
            <template v-slot:item.description ="{ item }">
              <p>{{ item.description }}</p>
            </template>
            <template v-slot:item.category ="{ item }">
              <p>{{ getCategoryName(item.category) }}</p>
            </template>
            <template v-slot:item.attributes ="{ item }">
              <p>{{ getAttrsInfos(item.attributes) }}</p>
            </template>
            <template v-slot:item.created ="{ item }">
              <p>{{ item.created | clearDate }}</p>
            </template>
  
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Discounts</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                 <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="dialog"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      New discount
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
  
                    <v-card-text>
                      <v-container>
                        <v-row>
                            <v-col
                            cols="12"
                            sm="6"
                            md="8"
                          >
                            <v-text-field
                              v-model="editedItem.description"
                              label="Description"
                              required
                            ></v-text-field>
                          </v-col>
                      
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                          <v-select
                              v-model.number="editedItem.procent"
                              label="Select procent"
                              item-text="name"
                              item-value="id"
                              :items="procents"
                              required
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-select
                              v-model="editedItem.category"
                              label="Select product category"
                              :items="getCategories"
                              item-text="name"
                              item-value="id"
                              @change="changeAttributes"
                              required
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="12"
                          >
                            <v-btn
                              @click="addAttrDialog = true"
                              :disabled="editedItem.category === null"
                            >
                              Add attribute
                            </v-btn>
                            <v-data-table v-if="editedItem.attributes.length > 0"
                              :headers="attrsHeaders"
                              :items="editedItem.attributes">
                              <template v-slot:item.name="{ item }">
                                {{ getAttrName(item.attr_id) }}
                              </template>
                              <template v-slot:item.options="{ item, index }">
                                <v-select
                                v-model="editedItem.attributes[index].values"
                                label="select option"
                                :items="getAttrOptions(item.attr_id)"   
                                multiple
                                >
                                </v-select>
                              </template>
                              <template v-slot:item.actions="{ item }">
                                <v-icon
                                  small
                                  @click="deleteAttr(item)"
                                >
                                  mdi-delete
                                </v-icon>
                              </template>
                            </v-data-table>
                        </v-col>
                      </v-row> 
                      </v-container>
                    </v-card-text>
  
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="addAttrDialog" max-width="400px">
                <v-card>
                  <v-card-title>Add attribute to discount</v-card-title>
                  <v-card-text>
                    <v-select
                      v-model="selectedAttr"
                      :items="attributes"
                      item-text="name"
                      label="Select attribute"     
                      return-object      
                    ></v-select>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeAdd">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="addAttrToList">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">Delete this discount?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <!-- <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon> -->
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  
  export default {
    name: 'discounts',
    components: {
  
    },
    computed: {
      getDiscounts () {
        return this.$store.state.Discounts.list === null ? [] : this.$store.state.Discounts.list;
      },
      getCategories () {
        return this.$store.state.Categories.list === null ? [] : this.$store.state.Categories.list
      },
      getAttributes () {
        return this.$store.state.Attributes.list === null ? [] : this.$store.state.Attributes.list
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New discount' : 'Edit discount'
      },
    },
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        addAttrDialog: false,
        search: '',
        attributes: [],
        attrsHeaders: [
          { text: 'Name', value: 'name', width: '20%' },
          { text: 'Options', value: 'options', width: '50%' },
          { text: 'Actions', value: 'actions', width: '10%' }
        ],
        headers: [
          { text: 'Description', value: 'description', sortable: true },
          { text: 'Category', value: 'category', sortable: true },
          { text: 'Attributes', value: 'attributes', sortable: true},
          { text: 'Procent', value: 'procent', sortable: false },
          { text: 'Created', value: 'created', sortable: true },
          { text: 'Actions', value: 'actions', sortable: false }
        ],
        editedIndex: -1,
        editedItem: {
          id: null,
          category: null,
          description: '',
          attributes: [],
          procent: null
        },
        defaultItem: {
          id: null,
          category: null,
          description: '',
          attributes: [],
          procent: null
        },
        selectedOptions: [],
        selectedAttr: null,
        selectedCat: null,
        procents: this.$DISCOUNT_PROCENTS,
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      editedItem (val, oldVal) {
        if (val.category !== oldVal.category) {
          this.changeAttributes()
        }
      }
    },
    created () {
  
    },
    filters: {
      clearDate: function (dt) {
        const d = dt.split('T')
        const t = d[1].split('.')
        return `${d[0]} ${t[0]}`
      }
    },
    methods: {
      getAttrsInfos(attrs) {
        let res = ''
        for (const attr of attrs) {
          res += this.getAttrName(attr.attr_id) + ': [ ' + attr.values + ' ] '
        }
        return res
      }, 
      deleteAttr (item) {
        this.editedItem.attributes.splice(this.editedItem.attributes.indexOf(item), 1)
      },

      closeAdd () {
        this.addAttrDialog = false
      },

      getAttrOptions (id) {
        return this.getAttributes.filter(a => a.id === id)[0].options
      },

      addAttrToList () {
        this.editedItem.attributes.push({
          attr_id: this.selectedAttr.id,
          values: [],
        })
        this.addAttrDialog = false
        this.selectedOptions = this.selectedAttr.options
        this.$set(this, 'selectedAttr', null)
      },

      editAttr (item) {

      },

      changeAttributes () {
        this.attributes = []
        const category = this.getCategories.filter(c => c.id === this.editedItem.category)
        if (category.length > 0) {
          for (const ca of category[0].attributes) {
            for (const attr of this.getAttributes) {
              if (attr.id === ca) {
                this.attributes.push(attr)
              }
            }
          }
        }
      },

      getAttrName (id) {
        const attrs = this.getAttributes.filter(a => a.id === id)
        return attrs.length > 0 ? attrs[0].name : ''
      },

      getCategoryName (id) {
        const catts = this.getCategories.filter(a => a.id === id)
        return catts.length > 0 ? catts[0].name : ''
      },
  
      editItem (item) {
        this.editedIndex = this.$store.state.Discounts.list.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
  
      deleteItem (item) {
        this.editedIndex = this.$store.state.Discounts.list.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
  
      deleteItemConfirm () {
        this.$store.state.Discounts.list.splice(this.editedIndex, 1)
        this.$store.dispatch('Discounts/DELETE', this.editedItem.id)
        this.closeDelete()
      },
  
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      save () {
        if (this.editedIndex > -1) {
          if (this.editedItem.attributes.length == 0) return
          this.$store.dispatch('Discounts/SAVE', this.editedItem)
        } else {
          if (this.editedItem.category == null || this.editedItem.attributes.length == 0 || this.editedIndex.procent == null) {
            console.log(this.editedItem)
          }
          this.$store.dispatch('Discounts/NEW', this.editedItem)
        }
        this.close()
      }
    },
    mounted () {
      this.$store.dispatch('Discounts/LOAD')
    }
  }
  </script>
  
  <style lang="scss">
  
  </style>
  