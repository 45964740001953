<template>
    <v-container fluid grid-list-md>
      <v-layout>
        <v-flex md12>
          <v-data-table
            :headers="headers"
            :items="getArticles"
            :search="search"
            sort-by="id"
            class="elevation-1"
          >
            <template v-slot:item.title_eng ="{ item }">
              <p>{{ item.title_eng }}</p>
            </template>
            <template v-slot:item.created ="{ item }">
              <p>{{ item.created | clearDate }}</p>
            </template>
            <template v-slot:item.updated ="{ item }">
              <p>{{ item.updated | clearDate }}</p>
            </template>
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Articles</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                 <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog persistent
                  v-model="dialog"
                  max-width="80%"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      New article
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
  
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.title_eng"
                              label="Title(ENG)"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.title_fin"
                              label="Title(FIN)"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.meta_desc"
                              label="meta description"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.meta_tags"
                              label="meta tags"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row v-if="editedItem.img == undefined || editedItem.img == null || editedItem.img == ''">
                        <v-col
                          cols="12"
                          sm="6"
                          md="8"
                        >
                          <v-file-input
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                            v-model="file"
                            accept="image/jpeg,image/png"
                            label="Select image"
                          ></v-file-input>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                         <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="uploadFile"
                        >
                          Upload
                        </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                          <v-col
                          cols="12"
                          sm="6"
                          md="12"
                        >
                        <span>Article picture:  </span>
                          <a :href="editedItem.img" target="blank">{{ (editedItem.img !== undefined || editedItem.img !== null) ? editedItem.img : "" }}</a>
                          <v-icon
                            v-if="editedItem.img"
                            small
                            v-on:click.stop.prevent="deleteImg(editedItem.img)">
                            mdi-delete
                            </v-icon>
                        </v-col>
                      </v-row>
                        <v-row>
                          <v-col>
                           <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                Content(ENG)
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                
                                  <tiptap-vuetify
                                    v-model="editedItem.eng"
                                    :extensions="extensions"
                                  />
                             
                              </v-expansion-panel-content>
                            </v-expansion-panel>  <v-expansion-panel>
                              <v-expansion-panel-header>
                                Content(FIN)
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                              
                                  <tiptap-vuetify
                                    v-model="editedItem.fin"
                                    :extensions="extensions"
                                  />
                               
                              </v-expansion-panel-content>
                            </v-expansion-panel>

                          </v-expansion-panels>
                          </v-col>
                        </v-row>
                        
                      </v-container>
                    </v-card-text>
  
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">Delete this article?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogImg" max-width="1000" max-height="700">
                  <v-card>
                    <v-card-text class="headline">
                      <span v-if="currentImg !== undefined || currentImg !== ''">
                        <img :src="currentImg" style="width: 100%; height: auto"/>
                      </span>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeImg">Close</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
              </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

  export default {
    name: 'articles',
    components: {
      TiptapVuetify
    },
    computed: {
      getArticles () {
        return this.$store.state.Articles.list === null ? [] : this.$store.state.Articles.list;
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New article' : 'Edit article'
      },
    },
    data () {
      return {
        extensions: [
          History,
          Blockquote,
          Link,
          Underline,
          Strike,
          Italic,
          ListItem,
          BulletList,
          OrderedList,
          [Heading, {
            options: {
              levels: [1, 2, 3]
            }
          }],
          Bold,
          Code,
          HorizontalRule,
          Paragraph,
          HardBreak
        ],
        dialog: false,
        dialogDelete: false,
        search: '',
        headers: [
          { text: 'Title', value: 'title_eng', sortable: true },
          { text: 'Created', value: 'created', sortable: true },
          { text: 'Updated', value: 'updated', sortable: true },
          { text: 'Actions', value: 'actions', sortable: false }
        ],
        editedIndex: -1,
        editedItem: {
          id: null,
          title_eng: '',
          title_fin: '',
          eng: '',
          fin: '',
          meta_desc: '',
          meta_tags: '',
          img: ''
        },
        defaultItem: {
          id: null,
          title_eng: '',
          title_fin: '',
          eng: '',
          fin: '',
          meta_desc: '',
          meta_tags: '',
          img: ''
        },
        currentImg: '',
        dialogImg: false,
        file: null,
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
  
    },
    filters: {
      clearDate: function (dt) {
        const d = dt.split('T')
        const t = d[1].split('.')
        return `${d[0]} ${t[0]}`
      }
    },
    methods: {
      closeImg () {
        this.dialogImg = false
        this.currentImg = ''
      },

      openImg (url) {
        this.currentImg = url
        this.dialogImg = true
      },
      deleteImg (img) {
        if (img !== undefined && img !== '') {
          const temp = img.split('images')
          if (temp.length < 2) return
          let fname = temp[temp.length - 1]
          if (fname[0] === '/') {
            fname = fname.slice(1)
          }
          const body = {
            filename: fname
          }
          fetch(`https://${process.env.VUE_APP_BACKEND}/product/image/delete`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Basic ' + window.btoa(`${this.$store.state.Auth.username}:${this.$store.state.Auth.password}`)
            },
            body: JSON.stringify(body)
          }).then(response => {
            if (response.status === 200) {
              this.$toast.success('Image deleted')
              this.editedItem.img = ''
              this.currentImg = ''
              this.$store.dispatch('Articles/SAVE', this.editedItem)
                .then(response => {
                  this.$toast.success('Article saved')
                })
                .catch(err => this.$toast.error(`Saving error: ${err}`))
            }
          }).catch(err => {
            this.$toast.error('Image delete: ', err)
            console.log(err)
          })
        } else {
          this.$toast.success('Image deleted')
          this.editedItem.img = ''
          this.currentImg = ''
        }
      },

      uploadFile () {
        if (this.file === null) return
        const formData = new FormData()
        formData.append('file', this.file)
        fetch(`https://${process.env.VUE_APP_BACKEND}/product/image`, {
          method: 'POST',
          headers: {
            Authorization: 'Basic ' + window.btoa(`${this.$store.state.Auth.username}:${this.$store.state.Auth.password}`)
          },
          body: formData
        }).then(response => {
          response.json().then(body => {
            this.$toast.success('Image uploaded')
            if (this.editedItem.img === undefined) {
              this.$set(this.editedItem, 'img', '')
            }
            this.editedItem.img = body.src
            this.$store.dispatch('Articles/SAVE', this.editedItem)
              .then(response => {
                this.$toast.success('Articles saved')
              })
              .catch(err => this.$toast.error(`Saving error: ${err}`))
          })
        }).catch(err => {
          this.$toast.error('Image upload: ', err)
          console.log(err)
        })
      },

      editItem (item) {
        this.editedIndex = this.$store.state.Articles.list.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
  
      deleteItem (item) {
        this.editedIndex = this.$store.state.Articles.list.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
  
      deleteItemConfirm () {
        this.$store.state.Articles.list.splice(this.editedIndex, 1)
        this.$store.dispatch('Articles/DELETE', this.editedItem.id)
        this.closeDelete()
      },
  
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      save () {
        console.log('item: ',this.editedItem)
        if (this.editedIndex > -1) {
          this.$store.dispatch('Articles/SAVE', this.editedItem)
        } else {
          this.$store.dispatch('Articles/NEW', this.editedItem)
        }
        this.close()
        this.editedItem = this.defaultItem
      }
    },
    mounted () {
      this.$store.dispatch('Articles/LOAD')
    }
  }
  </script>
  
  <style lang="scss">
  
  </style>
  