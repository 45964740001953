import Vue from 'vue'
import Vuex from 'vuex'

import products from '@/store/modules/products'
import categories from '@/store/modules/categories'
import attributes from '@/store/modules/attributes'
import warehouses from '@/store/modules/warehouses'
import images from '@/store/modules/images'
import invoices from '@/store/modules/invoices'
import auth from '@/store/modules/auth'
import users from '@/store/modules/users'
import discounts from '@/store/modules/discounts'
import articles from '@/store/modules/articles'

Vue.use(Vuex)

const store = new Vuex.Store({})
store.registerModule('Products', products)
store.registerModule('Categories', categories)
store.registerModule('Attributes', attributes)
store.registerModule('Warehouses', warehouses)
store.registerModule('Images', images)
store.registerModule('Invoices', invoices)
store.registerModule('Auth', auth)
store.registerModule('Users', users)
store.registerModule('Discounts', discounts)
store.registerModule('Articles', articles)

export default store
