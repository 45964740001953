<template>
  <v-container centered mt-10 ml-15>
    <form @submit="login">
      <v-row>
        <v-flex md4>
          <v-text-field
            label="Username"
            v-model="username"
          />
        </v-flex>
      </v-row>
      <v-row>
        <v-flex md4>
          <v-text-field
            label="Password"
            v-model="password"
            type="password"
          />
        </v-flex>
      </v-row>
      <v-row>
        <v-flex md4>
          <v-btn
            @click="login"
          >Login
          </v-btn>
        </v-flex>
      </v-row>
    </form>
  </v-container>
</template>

<script>
import axios from 'axios'
import AXIOS from '../store/modules/util'

export default {
  name: 'Login',
  data: () => {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    login () {
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
      axios.get(`https://${process.env.VUE_APP_BACKEND}/check`, {
        auth: {
          username: this.username,
          password: this.password
        }
      }).then(response => {
        this.$store.dispatch('Auth/LOG_IN', response.data)
        AXIOS.interceptors.request.use(config => {
          config.headers.Authorization = 'Basic ' + btoa(this.username + ':' + this.password)
          config.headers['Access-Control-Allow-Origin'] = '*'
          return config
        })
        this.$store.dispatch('Auth/SET_CREDS', { username: this.username, password: this.password })
        this.$router.push('/products')
      }).catch(error => {
        this.$store.dispatch('Auth/LOG_OUT')
        console.log(error)
      })
    }
  },
  mounted () {
  }
}
</script>
