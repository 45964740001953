import Vue from 'vue'
import VueRouter from 'vue-router'
import products from '../views/products.vue'
import categories from '../views/categories'
import attributes from '../views/attributes'
import warehouses from '../views/warehouses'
import invoices from '../views/invoices'
import settings from '../views/settings'
import login from '../components/login'
import store from '../store'
import users from '../views/users'
import discounts from '../views/discounts'
import articles from '../views/articles'

Vue.use(VueRouter)

const routes = [
  {
    path: '/discounts',
    name: 'discounts',
    component: discounts,
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'users',
    component: users,
    meta: { requiresAuth: true }
  },
  {
    path: '/products',
    name: 'products',
    component: products,
    meta: { requiresAuth: true }
  },
  {
    path: '/categories',
    name: 'categories',
    component: categories,
    meta: { requiresAuth: true }
  },
  {
    path: '/attributes',
    name: 'attributes',
    component: attributes,
    meta: { requiresAuth: true }
  },
  {
    path: '/warehouses',
    name: 'warehouses',
    component: warehouses,
    meta: { requiresAuth: true }
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: invoices,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'settings',
    component: settings,
    meta: { requiresAuth: true }
  },
  {
    path: '/articles',
    name: 'articles',
    component: articles,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.Auth.loggedIn) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
