<template>
  <v-container fluid grid-list-md>
    <v-layout>
      <v-flex md12>
        <h4>Application settings</h4>
        <hr/><br/>
        <ul>
          <li>
            <label>Synchronize database with site</label>
            <v-btn
              color="primary"
              dark
              class="mb-2 ml-3"
              @click="SyncDB">Sync
            </v-btn>
          </li>
        </ul>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'settings',
  components: {

  },
  data () {
    return {

    }
  },
  methods: {
    SyncDB () {
      console.log('Start sync DB')
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss">

</style>
