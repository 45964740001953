<template>
  <v-container fluid grid-list-md>
    <v-layout>
      <v-flex md12>
        <v-data-table
          :headers="headers"
          :items="attributesList"
          :search="search"
          sort-by="id"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Attributes</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
               <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New attribute
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.name"
                            label="Attribute name"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model.number="editedItem.id"
                            label="Id"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model.number="editedItem.position"
                            label="Position"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-checkbox
                            v-model="editedItem.visible"
                            label="Visible"
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-checkbox
                            v-model="editedItem.variation"
                            label="Variation"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                         <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                          <v-text-field
                            placeholder="Add new option to attribute"
                            v-model="newOption"
                            @keyup.enter="addOption"/>
                          <ol>
                            <li v-for="option in editedItem.options" :key="option">
                              <div class="optionList">
                                <label>{{option}}</label>
                                <v-icon small right @click="removeOption(option)">mdi-delete</v-icon>
                              </div>
                            </li>
                          </ol>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Delete this attribute?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'attributes',
  components: {

  },
  computed: {
    attributesList () {
      return this.$store.state.Attributes.list === null ? [] : this.$store.state.Attributes.list
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New attribute' : 'Edit attribute'
    }
  },
  data () {
    return {
      dialog: false,
      dialogDelete: false,
      search: '',
      newOption: '',
      headers: [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Position', value: 'position' },
        { text: 'Visible', value: 'visible', sortable: true },
        { text: 'Options', value: 'options' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        name: '',
        position: 0,
        visible: true,
        variation: true,
        options: []
      },
      defaultItem: {
        id: null,
        name: '',
        position: 0,
        visible: true,
        variation: true,
        options: []
      }
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  created () {

  },
  methods: {
    addOption () {
      if (this.editedItem.options === undefined) {
        this.$set(this.editedItem, 'options', [])
      }
      for (const o of this.editedItem.options) {
        if (o === this.newOption) return
      }
      this.editedItem.options.push(this.newOption)
      this.$set(this, 'newOption', '')
    },

    removeOption (option) {
      this.editedItem.options.splice(this.editedItem.options.indexOf(option), 1)
    },

    editItem (item) {
      this.editedIndex = this.$store.state.Attributes.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.$store.state.Attributes.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.$store.state.Attributes.list.splice(this.editedIndex, 1)
      this.$store.dispatch('Attributes/DELETE', this.editedItem.id)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        this.$store.dispatch('Attributes/SAVE', this.editedItem)
      } else {
        this.$store.dispatch('Attributes/NEW', this.editedItem)
      }
      this.close()
    }
  },
  mounted () {
    this.$store.dispatch('Attributes/LOAD')
  }
}
</script>

<style lang="scss">
  div.optionList {
    width: 80%;
  }
  div.optionList > label {
    font-size: 1.2em;
  }
</style>
