import axios from 'axios'

export var loggedIn = false

const options = {
  baseURL: `https://${process.env.VUE_APP_BACKEND}`,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
}

// eslint-disable-next-line new-cap
const AXIOS = new axios.create(options)

export default AXIOS
