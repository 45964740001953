<template>
  <v-app>
    <v-app-bar app>
      <router-link to="/"><img src="./assets/logo.png" /></router-link>
      <router-link to="/products"><v-btn class="ml-5">Products</v-btn></router-link>
      <router-link to="/discounts" v-show="role === 1 || role === 3"><v-btn class="ml-2">Discounts</v-btn></router-link>
      <router-link to="/articles" v-show="role === 1 || role === 3"><v-btn class="ml-2">Articles</v-btn></router-link>
      <router-link to="/categories" v-show="role === 1 || role === 3"><v-btn class="ml-2">Categories</v-btn></router-link>
      <router-link to="/attributes" v-show="role === 1 || role === 3"><v-btn class="ml-2">Attributes</v-btn></router-link>
      <router-link to="/invoices"><v-btn class="ml-2">Invoices</v-btn></router-link>
      <router-link to="/warehouses" v-show="role === 1 || role === 3"><v-btn class="ml-2">Warehouses</v-btn></router-link>
      <router-link to="/users" v-show="role === 1"><v-btn class="ml-2">Users</v-btn></router-link>
      <router-link to="/settings" v-show="role === 1"><v-btn class="ml-2">Settings</v-btn></router-link>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {

  },
  computed: {
    role () {
      return this.$store.state.Auth.role
    }
  },
  data: () => {
    return {
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss">
  a {
    text-decoration: none;
  }
  #app > div > header {
    background-color: #FFFF !important;
  }
  .container {
    padding-top: 6px;
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 3px;
  }
</style>
