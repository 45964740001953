const state = {
  loggedIn: false,
  role: 0,
  username: '',
  password: ''
}

const mutations = {
  LOGIN (state, value) {
    state.loggedIn = true
    state.role = value
  },
  LOGOUT (state) {
    state.loggedIn = false
  },
  CREDS (state, value) {
    state.username = value.username
    state.password = value.password
  }
}

const actions = {
  LOG_IN ({ state, commit }, value) {
    commit('LOGIN', value)
  },
  LOG_OUT ({ state, commit }) {
    commit('LOGOUT')
  },
  SET_CREDS ({ state, commit }, value) {
    commit('CREDS', value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
