import AXIOS from './util'

var loaded = false

const state = {
  list: []
}

const mutations = {
  LIST (state, value) {
    state.list = value
    loaded = true
  },
  SET (state, value) {
    state.list.filter(item => {
      if (item.id === value.id) {
        const i = state.list.indexOf(item)
        state.list[i] = value
      }
    })
  }
}

const actions = {
  INIT ({ state, commit, dispatch }) {
    dispatch('LOAD')
  },
  // SET_LIST ({ state, commit }, value) {
  //   commit('LIST', value)
  // },
  RELOAD ({ state, commit, dispatch }) {
    return AXIOS.get('/products')
      .then((response) => {
        commit('LIST', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  SET_PRODUCT ({ state, commit }, value) {
    commit('SET', value)
  },
  LOAD ({ state, commit, dispatch }) {
    if (loaded) {
      return
    }
    return dispatch('RELOAD')
  },
  NEW ({ state, commit, dispatch }, value) {
    return AXIOS.post('/products', [value])
      .then((response) => {
        dispatch('RELOAD')
      })
      .catch((error) => {
        console.log(error)
      })
  },
  SAVE ({ state, commit, dispatch }, value) {
    return AXIOS.put('/product', value)
      .then((response) => {
        dispatch('RELOAD')
      })
      .catch((error) => {
        console.log(error)
      })
  },
  DELETE ({ state, commit, dispatch }, value) {
    return AXIOS.delete('/product/' + value)
      .then((response) => {
        dispatch('RELOAD')
      })
      .catch((error) => {
        console.log(error)
      })
  },
  DELETE_MANY ({ state, commit, dispatch }, value) {
    return AXIOS.post('/products/delete', value)
      .then((response) => {
        dispatch('RELOAD')
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
