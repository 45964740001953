<template>
  <v-container fluid grid-list-md>
    <v-layout>
      <v-flex md12>
        <v-data-table
          :headers="headers"
          :items="filteredProducts"
          :item-class="itemRowBackground"
          :search="search"
          sort-by="updated"
          sort-desc
          :loading="loadTable"
          fixed-header
          :height="getHeight"
          loading-text="Please wait, data loading..."
          class="elevation-1"
          :show-select="showSelect"
          @item-selected="itemSelect"
          @toggle-select-all="itemSelectAll"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 30, 50]
          }"
        >

          <template v-slot:item.sku="{ item }">
            <a :href="getSiteLink(item)" target="blank">
              {{ item.sku }}
            </a>
          </template>

          <template v-slot:item.height="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 3 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.moto_width="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 19 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.moto_height="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 20 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.moto_radius="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 21 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.width="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 2 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.radius="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id" style="margin-top: 10px">
              {{ attr.id == 1 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.index="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 17 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.manufacturer="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 5 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.year="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 16 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.condition="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 15 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.season="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 4 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.rim_width="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 10 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.pcd="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 7 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.diameter="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 8 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.dia="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 9 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.et="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 11 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.stick_diameter="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 14 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.brand="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 12 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.cap_size="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 13 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.stock_quantity="{ item }">
            <p>
             {{ item.stock_quantity }}
            </p>
          </template>

          <template v-slot:item.price="{ item }">
            <p>
             {{ item.price }}
            </p>
          </template>

          <template v-slot:item.name="{ item }">
            <p>
             {{ item.name }}
            </p>
          </template>

          <template v-slot:item.description="{ item }">
            <p>
             {{ item.description }}
            </p>
          </template>

          <template v-slot:item.type="{ item }">
            <p v-for="attr in item.attributes" :key="attr.id">
              {{ attr.id == 18 ? attr.value : "" }}
            </p>
          </template>

          <template v-slot:item.warehouse="{ item }">
            <p style="margin-top: 10px">
             {{ getWarehouseName(item.warehouse) }}
            </p>
          </template>

          <template v-slot:item.images="{ item }">
            <span v-if="item.images !== undefined">
              <span v-for="img in item.images" :key="img.src">
                <!-- <a :href="img.src" target="blank"> -->
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    @click="openImg(img.src)"
                    >
                    mdi-camera
                  </v-icon>
                <!-- </a> -->
              </span>
            </span>
          </template>

          <template v-slot:item.updated="{ item }">
            <p>
             {{ item.updated | clearDate }}
            </p>
          </template>

          <template v-slot:top>
            <v-toolbar
              flat
            >
               <v-spacer></v-spacer>
                <v-text-field
                  v-model.lazy="search"
                  append-icon="mdi-magnify"
                  label="model/article"
                  single-line
                  hide-details
                ></v-text-field>
              <v-spacer></v-spacer>
                 <v-text-field
                  v-if="categoryFilter == 1 || categoryFilter == 8"
                  v-model.lazy="searchWidth"
                  label="width"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
               <v-spacer></v-spacer>
               <v-text-field
                  v-if="categoryFilter == 7"
                  v-model.lazy="searchMotoWidth"
                  label="width"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
               <v-spacer></v-spacer>
                 <v-text-field
                  v-if="categoryFilter == 1 || categoryFilter == 8"
                  v-model.lazy="searchHeight"
                  label="height"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
               <v-spacer></v-spacer>
               <v-text-field
                  v-if="categoryFilter == 7"
                  v-model.lazy="searchMotoHeight"
                  label="height"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
               <v-spacer></v-spacer>
                 <v-text-field
                 v-if="categoryFilter == 1 || categoryFilter == 5"
                  v-model.lazy="searchRadius"
                  label="radius"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              <v-spacer></v-spacer>
              <v-autocomplete
                v-if="categoryFilter == 2 || categoryFilter == 3 || categoryFilter == 8"
                style="padding-top:20px"
                v-model="searchRimWidth"
                :items="getRimWidth"
                color="white"
                clearable
                label="rim width"
              ></v-autocomplete>
               <v-spacer></v-spacer>
               <v-autocomplete
                v-if="categoryFilter == 2 || categoryFilter == 3 || categoryFilter == 8"
                style="padding-top:20px"
                v-model="searchPCD"
                :items="getPCD"
                color="white"
                clearable
                label="pcd"
              ></v-autocomplete>
               <v-spacer></v-spacer>
               <v-autocomplete
                v-if="categoryFilter == 2 || categoryFilter == 3"
                style="padding-top:20px"
                v-model="searchET"
                :items="getET"
                color="white"
                clearable
                label="et"
              ></v-autocomplete>
               <v-spacer></v-spacer>
               <v-autocomplete
                v-if="categoryFilter == 2 || categoryFilter == 3 || categoryFilter == 8"
                style="padding-top:20px"
                v-model="searchDiameter"
                :items="getDiameter"
                color="white"
                clearable
                label="diameter"
              ></v-autocomplete>
               <v-spacer></v-spacer>
               <v-select
                v-if="categoryFilter == 1"
                label="season"
                :items="seasons"
                item-text="name"
                item-value="id"
                clearable
                multiple
                v-model="seasonFilter"
                single-line
                hide-details
               />
               <v-spacer></v-spacer>
               <v-select
                v-if="categoryFilter == 4"
                label="stick diameter"
                :items="getStickDiameter"
                item-text="name"
                item-value="id"
                clearable
                multiple
                v-model="searchStickDiameter"
                single-line
                hide-details
               />
              <v-spacer></v-spacer>
              <v-autocomplete
                v-if="categoryFilter == 7"
                style="padding-top:20px"
                v-model="searchMotoRadius"
                :items="getMotoRadius"
                color="white"
                clearable
                label="radius"
              ></v-autocomplete>
              <v-spacer></v-spacer>
              <v-autocomplete
                v-if="categoryFilter == 7"
                style="padding-top:20px"
                v-model="searchIndex"
                :items="getIndex"
                color="white"
                clearable
                label="index"
              ></v-autocomplete>
              <v-spacer></v-spacer>
              <v-select
               v-if="categoryFilter == 7"
                label="type"
                :items="getMotoTypes"
                item-text="name"
                item-value="id"
                clearable
                v-model="searchMotoType"
                single-line
                hide-details
               />
               <v-spacer></v-spacer>
               <v-select
               v-if="categoryFilter == 4 || categoryFilter == 6 || categoryFilter == 5"
                label="brand"
                :items="getBrand"
                item-text="name"
                item-value="id"
                clearable
                v-model="searchBrand"
                single-line
                hide-details
               />
               <v-spacer></v-spacer>
               <v-select
               v-if="categoryFilter == 6"
                label="cap size"
                :items="getCapSize"
                item-text="name"
                item-value="id"
                clearable
                v-model="searchCapSize"
                single-line
                hide-details
               />
               <v-spacer></v-spacer>
              <v-autocomplete
                v-if="categoryFilter == 1 || categoryFilter == 7 || categoryFilter == 8"
                style="padding-top:20px"
                v-model="searchManufacturer"
                :items="getManufacturer"
                color="white"
                clearable
                label="manufacturer"
              ></v-autocomplete>
              <v-spacer></v-spacer>
              <v-select
                label="warehouse"
                :items="getWarehouses"
                item-text="name"
                item-value="id"
                clearable
                v-model="warehouseFilter"
                single-line
                hide-details
               />
               <v-spacer></v-spacer>
               <v-select
                label="category"
                :items="getCategories"
                item-text="name"
                item-value="id"
                clearable
                v-model="categoryFilter"
                single-line
                hide-details
               />
               <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New
                  </v-btn>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2 mr-2"
                    @click="trash"
                  >
                    Trash
                  </v-btn>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2 mr-2"
                    @click="refresh"
                  >
                    Refresh
                  </v-btn>
                   <v-btn
                    color="primary"
                    dark
                    class="mb-2 mr-2"
                    @click="clear"
                  >
                    Clear
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">
                      {{ formTitle }}
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.sku"
                            label="Article (SKU)"
                            :disabled="role == 4"
                          ></v-text-field>
                        </v-col>
                          <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-select
                            v-model="editedItem.category"
                            label="Category"
                            :items="getCategories"
                            item-text="name"
                            item-value="id"
                            @change="changeCategory"
                            :disabled="role == 4"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="8"
                        >
                          <v-file-input
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                            v-model="files"
                            accept="image/jpeg,image/png"
                            label="Select image"
                            :disabled="role == 4"
                          ></v-file-input>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                         <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="uploadFile"
                          :disabled="role == 4"
                        >
                          Upload
                        </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                          <v-col
                          cols="12"
                          sm="6"
                          md="12"
                        >
                          <v-list>
                            <v-list-item v-for="image in editedItem.images" :key="image">
                              <v-list-item-content v-if="image.src !== undefined">
                                <a :href="image.src" target="blank">{{ image.src }}
                                  <v-icon
                                    v-if="role !== 4"
                                    small
                                    v-on:click.stop.prevent="deleteImg(image)">
                                    mdi-delete
                                  </v-icon>
                                </a>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                      <v-row>
                       <v-col
                          cols="12"
                          sm="6"
                          md="12"
                        >
                          <v-text-field
                            v-model="editedItem.name"
                            label="Model"
                            :disabled="role == 4"
                            type="text"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model.number="editedItem.price"
                            type="number"
                            label="Price"
                            :disabled="role == 4"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.price_description"
                            label="à"
                            :disabled="role == 4"
                          ></v-text-field>
                        </v-col>
                          <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-model="editedItem.warehouse"
                            label="Warehouse"
                            :items="getWarehouses"
                            item-text="name"
                            item-value="id"
                            :disabled="role == 4"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model.number="editedItem.stock_quantity"
                            label="Quantity"
                            :disabled="role == 4"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="8"
                        >
                           <v-text-field
                            v-model="editedItem.description"
                            label="Description"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-if="editedItem.category == 2 || editedItem.category == 3">
                        <v-col
                          cols="12"
                          sm="6"
                          md="12"
                        >
                          <v-text-field
                            v-model="editedItem.short_description"
                            label="Additional information"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                            <v-col v-for="(attribute, index) in editedItem.attributes" :key="attribute.id"
                              cols="12"
                              sm="6"
                              md="4">
                              <v-combobox
                                :items="getAttributeOptionsById(attribute.id)"
                                :label="getAttrName(attribute.id)"
                                v-model="editedItem.attributes[index].value"
                                return-object
                                :disabled="role == 4"
                              />
                            </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <span v-if="editedItem.created">Created: {{ editedItem.created | clearDate }}</span>
                    <span v-if="editedItem.del_time">Deleted: {{ editedItem.del_time | clearDate }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500">
                <v-card>
                  <v-card-title class="headline">Delete this product?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogImg" max-width="1000" max-height="700">
                <v-card>
                  <v-card-text class="headline">
                    <span v-if="currentImg !== undefined || currentImg !== ''">
                      <img :src="currentImg" style="width: 100%; height: auto"/>
                    </span>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeImg">Close</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogInvoice" max-width="700">
                <v-card>
                  <v-card-title class="headline">Add to invoice</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                          <v-autocomplete
                            v-model="editedInvoice"
                            :items="getInvoices"
                            color="white"
                            item-text="number"
                            @change="checkInvoice"
                            label="Select invoice"
                            return-object
                          ></v-autocomplete>
                        </v-row>
                          <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model.number="editedInvoice.number"
                            label="Invoice number"
                            :readonly="true"
                            required
                          ></v-text-field>
                        </v-col>
                         <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-model="editedInvoice.type"
                            :items="types"
                            item-text="name"
                            item-value="id"
                            label="Type"
                            :disabled="checkStatus"
                            required
                          ></v-select>
                        </v-col>
                         <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-model="editedInvoice.status"
                            :items="statuses"
                            item-text="name"
                            item-value="id"
                            label="Status"
                            :disabled="editedInvoice.status === 2"
                            required
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                         <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-select
                            v-model="editedInvoice.from"
                            :items="getWarehouses"
                            item-text="name"
                            item-value="id"
                            label="From"
                            :disabled="checkStatus"
                            required
                          ></v-select>
                        </v-col>
                         <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-select
                            v-model="editedInvoice.to"
                            :items="getWarehouses"
                            item-text="name"
                            item-value="id"
                            label="To"
                            :disabled="checkStatus || editedInvoice.type === 1"
                            required
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                         <v-col
                          cols="12"
                          sm="6"
                          md="12"
                        >
                          <v-text-field
                            v-model="editedInvoice.description"
                            label="Description"
                            :readonly="editedInvoice.status === 2"
                          ></v-text-field>
                        </v-col>
                        <v-data-table v-if="editedInvoice.products.length > 0"
                            :headers="countHeaders"
                            :items="editedInvoice.products">
                            <template v-slot:item.product="{ item }">
                              {{ getProductName(item.product) }}
                            </template>
                            <template v-slot:item.count="{ item, index }">
                                <v-text-field
                                  type="number"
                                  v-model.number="editedInvoice.products[index].count"
                                  :max="getProductCount(editedInvoice.products[index].product)"
                                  :min="1"
                                />
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                small
                                @click="deleteProduct(item)"
                                :disabled="checkStatus"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="clearInvoice">Clear</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeInvoice">Cancel</v-btn>
                    <v-btn color="blue darken-1"
                      text
                      @click="addInvoiceConfirm"
                      :disabled="checkData">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogHistory" max-width="700">
                <v-card>
                  <v-card-title class="headline">History changes</v-card-title>
                  <v-card-text>
                    <ul>
                      <li v-for="change in history" :key="change.field">
                        Time: <b>{{change.dt | clearDate}}</b> |
                        Author: <b>{{change.author}}</b> |
                        Field: <b>{{change.field}}</b> |
                        Old: <b>{{change.old_value}}</b> |
                        New: <b>{{change.new_value}}</b>
                      </li>
                    </ul>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeHistory">Close</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-1"
                :disabled="getProductCount(item.id) === 0 || !checkWarehouse(item.warehouse)"
                @click="addInvoice(item)">
                mdi-clipboard-plus
              </v-icon>
            </template>
            <span>Add to invoice</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-1"
                :disabled="item.changes == undefined"
                @click="showHistory(item.changes)">
                mdi-history
              </v-icon>
            </template>
            <span>History changes</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-1"
                @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit product</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.deleted">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="role !== 4"
                v-bind="attrs"
                v-on="on"
                small
                @click="setDeleted(item)">
                mdi-minus
              </v-icon>
            </template>
            <span>Move to trash</span>
           </v-tooltip>
           <v-tooltip bottom v-if="item.deleted && showDeleted">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="role !== 4"
                v-bind="attrs"
                v-on="on"
                small
                @click="recoverDeleted(item)">
                mdi-plus
              </v-icon>
            </template>
            <span>Recover from trash</span>
           </v-tooltip>
           <v-tooltip bottom v-if="selected.length == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="role !== 4"
                v-bind="attrs"
                v-on="on"
                small
                @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <span>Delete this selected product</span>
           </v-tooltip>
           <v-tooltip bottom v-if="selected.length > 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="role !== 4"
                v-bind="attrs"
                v-on="on"
                small
                color="red"
                @click="deleteMany()">
                mdi-delete
              </v-icon>
            </template>
            <span>Delete all selected products</span>
           </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'products',
  components: {

  },
  computed: {
    getHeight () {
      return `${window.innerHeight - 210}px`
    },
    role () {
      return this.$store.state.Auth.role
    },
    productsList () {
      return this.$store.state.Products.list
    },
    getCategories () {
      return this.$store.state.Categories.list
    },
    getWarehouses () {
      return this.$store.state.Warehouses.list
    },
    getAttributes () {
      return this.$store.state.Attributes.list
    },
    getInvoices () {
      let list = []
      if (this.$store.state.Invoices.list !== null) {
        list = this.$store.state.Invoices.list.filter(i => i.status !== 2)
      }
      return list
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New product' : 'Edit product'
    },
    filteredProducts () {
      const getAttrById = (arr, id) => {
        for (const a of arr) {
          if (a.id === id) {
            return a.value
          }
        }
      }
      return this.productsList.filter(i => {
        if (i.stock_quantity === 0 && this.role !== 1) return false
        if (this.showDeleted) {
          if (!i.deleted) return false
        }
        if (this.warehouseFilter) {
          if (i.warehouse !== parseInt(this.warehouseFilter, 10)) return false
        }
        if (this.categoryFilter) {
          if (i.category !== parseInt(this.categoryFilter, 10)) return false
        }
        if (this.seasonFilter.length !== 0 && getAttrById(i.attributes, 4) !== undefined) {
          let finded = false
          for (const s of this.seasonFilter) {
            if (s.includes(getAttrById(i.attributes, 4))) finded = true
          }
          if (!finded) return false
        }
        if (this.searchWidth) {
          if (getAttrById(i.attributes, 2) !== this.searchWidth) return false
        }
        if (this.searchHeight) {
          if (getAttrById(i.attributes, 3) !== this.searchHeight) return false
        }
        if (this.searchRadius) {
          if (this.searchRadius.slice(-1) === ' ') {
            if (!this.searchRadius.includes(getAttrById(i.attributes, 1))) return false
          } else {
            if (!getAttrById(i.attributes, 1).toLowerCase().includes(this.searchRadius.toLowerCase())) return false
          }
        }
        if (this.searchManufacturer) {
          if (!getAttrById(i.attributes, 5).includes(this.searchManufacturer)) return false
        }
        if (this.searchRimWidth) {
          if (!getAttrById(i.attributes, 10).includes(this.searchRimWidth)) return false
        }
        if (this.searchPCD) {
          if (!getAttrById(i.attributes, 7).includes(this.searchPCD)) return false
        }
        if (this.searchET) {
          if (!getAttrById(i.attributes, 11).includes(this.searchET)) return false
        }
        if (this.searchDIA) {
          if (!getAttrById(i.attributes, 9).includes(this.searchDIA)) return false
        }
        if (this.searchDiameter) {
          if (!getAttrById(i.attributes, 8).includes(this.searchDiameter)) return false
        }
        if (this.searchIndex) {
          if (!getAttrById(i.attributes, 17).includes(this.searchIndex)) return false
        }
        if (this.searchMotoType) {
          if (!getAttrById(i.attributes, 18).includes(this.searchMotoType)) return false
        }
        if (this.searchMotoWidth) {
          if (!getAttrById(i.attributes, 19).includes(this.searchMotoWidth)) return false
        }
        if (this.searchMotoHeight) {
          if (!getAttrById(i.attributes, 20).includes(this.searchMotoHeight)) return false
        }
        if (this.searchMotoRadius) {
          if (!getAttrById(i.attributes, 21).includes(this.searchMotoRadius)) return false
        }
        if (this.searchStickDiameter) {
          if (!getAttrById(i.attributes, 14).includes(this.searchStickDiameter)) return false
        }
        if (this.searchBrand) {
          if (!getAttrById(i.attributes, 12).includes(this.searchBrand)) return false
        }
        if (this.searchCapSize) {
          if (!getAttrById(i.attributes, 13).includes(this.searchBrand)) return false
        }
        return true
      })
    },
    checkStatus () {
      return this.editedInvoice.status !== 0
    },
    checkData () {
      return this.editedInvoice.type === null || this.editedInvoice.status === null || this.editedInvoice.products.length === 0 || this.editedInvoice.from === null
    },
    getManufacturer () {
      const list = this.getAttributes.filter(i => i.id === 5)
      return list.length > 0 ? list[0].options : []
    },
    getRimWidth () {
      const list = this.getAttributes.filter(i => i.id === 10)
      return list.length > 0 ? list[0].options : []
    },
    getPCD () {
      const list = this.getAttributes.filter(i => i.id === 7)
      return list.length > 0 ? list[0].options : []
    },
    getET () {
      const list = this.getAttributes.filter(i => i.id === 11)
      return list.length > 0 ? list[0].options : []
    },
    getDIA () {
      const list = this.getAttributes.filter(i => i.id === 9)
      return list.length > 0 ? list[0].options : []
    },
    getDiameter () {
      const list = this.getAttributes.filter(i => i.id === 8)
      return list.length > 0 ? list[0].options : []
    },
    getIndex () {
      const list = this.getAttributes.filter(i => i.id === 17)
      return list.length > 0 ? list[0].options : []
    },
    getMotoTypes () {
      const list = this.getAttributes.filter(i => i.id === 18)
      return list.length > 0 ? list[0].options : []
    },
    getMotoWidth () {
      const list = this.getAttributes.filter(i => i.id === 19)
      return list.length > 0 ? list[0].options : []
    },
    getMotoHeight () {
      const list = this.getAttributes.filter(i => i.id === 20)
      return list.length > 0 ? list[0].options : []
    },
    getMotoRadius () {
      const list = this.getAttributes.filter(i => i.id === 21)
      return list.length > 0 ? list[0].options : []
    },
    getStickDiameter () {
      const list = this.getAttributes.filter(i => i.id === 14)
      return list.length > 0 ? list[0].options : []
    },
    getBrand () {
      const list = this.getAttributes.filter(i => i.id === 12)
      return list.length > 0 ? list[0].options : []
    },
    getCapSize () {
      const list = this.getAttributes.filter(i => i.id === 13)
      return list.length > 0 ? list[0].options : []
    }
  },
  data () {
    return {
      selected: [],
      showSelect: false,
      currentImg: '',
      dialogImg: false,
      categoryFilter: null,
      warehouseFilter: null,
      seasonFilter: [],
      searchWidth: '',
      searchHeight: '',
      searchRadius: '',
      searchManufacturer: '',
      searchRimWidth: '',
      searchET: '',
      searchDIA: '',
      searchPCD: '',
      searchDiameter: '',
      searchIndex: '',
      searchMotoType: null,
      searchMotoWidth: '',
      searchMotoHeight: '',
      searchMotoRadius: '',
      searchStickDiameter: '',
      searchCapSize: '',
      searchBrand: '',
      seasons: [
        { id: 'kesä Kesärenkaat', name: 'Kesärenkaat' },
        { id: 'kitka Kitkarenkaat', name: 'Kitkarenkaat' },
        { id: 'nasta Nastarenkaat', name: 'Nastarenkaat' },
        { id: 'Muut renkaat', name: 'Muut renkaat' }
      ],
      dialog: false,
      dialogDelete: false,
      dialogInvoice: false,
      dialogHistory: false,
      loadTable: true,
      types: this.$INVOICE_TYPES,
      statuses: this.$INVOICE_STATUS,
      search: '',
      headers: [
        { text: 'Article (SKU)', value: 'sku', sortable: true },
        { text: 'Season', value: 'season', sortable: true },
        { text: 'Width', value: 'width', sortable: false },
        { text: 'Height', value: 'height', sortable: false },
        { text: 'Radius', value: 'radius', sortable: false },
        { text: 'Index', value: 'index', sortable: false },
        { text: 'Manufacturer', value: 'manufacturer', sortable: false },
        { text: 'Model', value: 'name', sortable: true },
        { text: 'DOT', value: 'year', sortable: false },
        { text: 'Condition', value: 'condition', sortable: true },
        { text: 'à', value: 'price_description', sortable: false },
        { text: 'Price', value: 'price', sortable: true },
        { text: 'Quantity', value: 'stock_quantity', sortable: true },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Images', value: 'images', sortable: false },
        { text: 'Warehouse', value: 'warehouse', sortable: true },
        { text: 'Updated', value: 'updated', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      countHeaders: [
        { text: 'Name', value: 'product', width: '70%', sortable: true },
        { text: 'Quantity', value: 'count', width: '20%' },
        { text: 'Actions', value: 'actions', width: '10%' }
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        sku: '',
        price: 0,
        price_description: '',
        description: '',
        short_description: '',
        stock_quantity: 0,
        category: null,
        warehouse: null,
        attributes: [],
        images: [],
        user: '',
        changes: []
      },
      defaultItem: {
        name: '',
        sku: '',
        price: 0,
        price_description: '',
        description: '',
        short_description: '',
        stock_quantity: 0,
        category: null,
        warehouse: null,
        attributes: [],
        images: [],
        user: '',
        changes: []
      },
      editedInvoice: {
        number: Math.round(Math.random() * (100000 - 9) + 9),
        type: null,
        from: null,
        to: null,
        products: [],
        description: '',
        user: null,
        status: null
      },
      tempForProduct: [],
      newInvoice: true,
      files: null,
      history: [],
      showDeleted: false
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogInvoice (val) {
      val || this.closeInvoice()
    },
    dialogHistory (val) {
      val || this.closeHistory()
    },
    categoryFilter (val) {
      this.genArticle()
      switch (val) {
        case 1:
          this.$set(this, 'headers', [
            { text: 'Article (SKU)', value: 'sku', sortable: true },
            { text: 'Season', value: 'season', sortable: true },
            { text: 'Width', value: 'width', sortable: false },
            { text: 'Height', value: 'height', sortable: false },
            { text: 'Radius', value: 'radius', sortable: false },
            { text: 'Index', value: 'index', sortable: false },
            { text: 'Manufacturer', value: 'manufacturer', sortable: false },
            { text: 'Model', value: 'name', sortable: true },
            { text: 'DOT', value: 'year', sortable: false },
            { text: 'Condition', value: 'condition', sortable: true },
            { text: 'à', value: 'price_description', sortable: false },
            { text: 'Price', value: 'price', sortable: true },
            { text: 'Quantity', value: 'stock_quantity', sortable: true },
            { text: 'Description', value: 'description', sortable: false },
            { text: 'Images', value: 'images', sortable: false },
            { text: 'Warehouse', value: 'warehouse', sortable: true },
            { text: 'Updated', value: 'updated', sortable: true },
            { text: 'Actions', value: 'actions', sortable: false }
          ])
          break
        case 2:
          this.$set(this, 'headers', [
            { text: 'Article (SKU)', value: 'sku', sortable: true },
            { text: 'PCD', value: 'pcd', sortable: false },
            { text: 'Diameter', value: 'diameter', sortable: false },
            { text: 'Rim Width', value: 'rim_width', sortable: false },
            { text: 'DIA', value: 'dia', sortable: false },
            { text: 'ET', value: 'et', sortable: false },
            { text: 'à', value: 'price_description', sortable: false },
            { text: 'Price', value: 'price', sortable: true },
            { text: 'Quantity', value: 'stock_quantity', sortable: true },
            { text: 'Model', value: 'name', sortable: true },
            { text: 'Description', value: 'description', sortable: false },
            { text: 'Info', value: 'short_description', sortable: false },
            { text: 'Images', value: 'images', sortable: false },
            { text: 'Warehouse', value: 'warehouse', sortable: true },
            { text: 'Updated', value: 'updated', sortable: true },
            { text: 'Actions', value: 'actions', sortable: false }
          ])
          break
        case 3:
          this.$set(this, 'headers', [
            { text: 'Article (SKU)', value: 'sku', sortable: true },
            { text: 'PCD', value: 'pcd', sortable: false },
            { text: 'Diameter', value: 'diameter', sortable: false },
            { text: 'Rim Width', value: 'rim_width', sortable: false },
            { text: 'DIA', value: 'dia', sortable: false },
            { text: 'ET', value: 'et', sortable: false },
            { text: 'à', value: 'price_description', sortable: false },
            { text: 'Price', value: 'price', sortable: true },
            { text: 'Quantity', value: 'stock_quantity', sortable: true },
            { text: 'Model', value: 'name', sortable: true },
            { text: 'Description', value: 'description', sortable: false },
            { text: 'Info', value: 'short_description', sortable: false },
            { text: 'Images', value: 'images', sortable: false },
            { text: 'Warehouse', value: 'warehouse', sortable: true },
            { text: 'Updated', value: 'updated', sortable: true },
            { text: 'Actions', value: 'actions', sortable: false }
          ])
          break
        case 4:
          this.$set(this, 'headers', [
            { text: 'Article (SKU)', value: 'sku', sortable: true },
            { text: 'Diameter', value: 'stick_diameter', sortable: true },
            { text: 'Brand', value: 'brand', sortable: false },
            { text: 'Model', value: 'name', sortable: true },
            { text: 'à', value: 'price_description', sortable: false },
            { text: 'Price', value: 'price', sortable: true },
            { text: 'Quantity', value: 'stock_quantity', sortable: true },
            { text: 'Description', value: 'description', sortable: false },
            { text: 'Images', value: 'images', sortable: false },
            { text: 'Warehouse', value: 'warehouse', sortable: true },
            { text: 'Updated', value: 'updated', sortable: true },
            { text: 'Actions', value: 'actions', sortable: false }
          ])
          break
        case 5:
          this.$set(this, 'headers', [
            { text: 'Article (SKU)', value: 'sku', sortable: true },
            { text: 'Radius', value: 'radius', sortable: false },
            { text: 'Brand', value: 'brand', sortable: false },
            { text: 'Model', value: 'name', sortable: true },
            { text: 'à', value: 'price_description', sortable: false },
            { text: 'Price', value: 'price', sortable: true },
            { text: 'Quantity', value: 'stock_quantity', sortable: true },
            { text: 'Description', value: 'description', sortable: false },
            { text: 'Images', value: 'images', sortable: false },
            { text: 'Warehouse', value: 'warehouse', sortable: true },
            { text: 'Updated', value: 'updated', sortable: true },
            { text: 'Actions', value: 'actions', sortable: false }
          ])
          break
        case 6:
          this.$set(this, 'headers', [
            { text: 'Article (SKU)', value: 'sku', sortable: true },
            { text: 'Cap size', value: 'cap_size', sortable: false },
            { text: 'Brand', value: 'brand', sortable: false },
            { text: 'Model', value: 'name', sortable: true },
            { text: 'à', value: 'price_description', sortable: false },
            { text: 'Price', value: 'price', sortable: true },
            { text: 'Quantity', value: 'stock_quantity', sortable: true },
            { text: 'Description', value: 'description', sortable: false },
            { text: 'Images', value: 'images', sortable: false },
            { text: 'Warehouse', value: 'warehouse', sortable: true },
            { text: 'Updated', value: 'updated', sortable: true },
            { text: 'Actions', value: 'actions', sortable: false }
          ])
          break
        case 7:
          this.$set(this, 'headers', [
            { text: 'Article (SKU)', value: 'sku', sortable: true },
            { text: 'Type', value: 'type', sortable: true },
            { text: 'Manufacturer', value: 'manufacturer', sortable: false },
            { text: 'Model', value: 'name', sortable: true },
            { text: 'Width', value: 'moto_width', sortable: false },
            { text: 'Height', value: 'moto_height', sortable: false },
            { text: 'Diameter', value: 'moto_radius', sortable: false },
            { text: 'Index', value: 'index', sortable: false },
            { text: 'DOT', value: 'year', sortable: false },
            { text: 'à', value: 'price_description', sortable: false },
            { text: 'Price', value: 'price', sortable: true },
            { text: 'Quantity', value: 'stock_quantity', sortable: true },
            { text: 'Description', value: 'description', sortable: false },
            { text: 'Images', value: 'images', sortable: false },
            { text: 'Warehouse', value: 'warehouse', sortable: true },
            { text: 'Updated', value: 'updated', sortable: true },
            { text: 'Actions', value: 'actions', sortable: false }
          ])
          break
        case 8:
          this.$set(this, 'headers', [
            { text: 'Article (SKU)', value: 'sku', sortable: true },
            { text: 'Manufacturer', value: 'manufacturer', sortable: false },
            { text: 'Model', value: 'name', sortable: true },
            { text: 'PCD', value: 'pcd', sortable: false },
            { text: 'Diameter', value: 'diameter', sortable: false },
            { text: 'DIA', value: 'dia', sortable: false },
            { text: 'Width', value: 'width', sortable: false },
            { text: 'Height', value: 'height', sortable: false },
            { text: 'Rim width', value: 'rim_width', sortable: false },
            { text: 'à', value: 'price_description', sortable: false },
            { text: 'Price', value: 'price', sortable: true },
            { text: 'Quantity', value: 'stock_quantity', sortable: true },
            { text: 'Description', value: 'description', sortable: false },
            { text: 'Images', value: 'images', sortable: false },
            { text: 'Warehouse', value: 'warehouse', sortable: true },
            { text: 'Updated', value: 'updated', sortable: true },
            { text: 'Actions', value: 'actions', sortable: false }
          ])
          break
      }
    }
  },
  methods: {
    itemSelect (item) {
      if (item.value) {
        if (!this.selected.includes(item.item.id)) this.selected.push(item.item.id)
      } else {
        if (this.selected.includes(item.item.id)) {
          const i = this.selected.indexOf(item.item.id)
          this.selected.splice(i, 1)
        }
      }
    },

    itemSelectAll (items) {
      this.selected = []
      if (items.value) {
        for (const item of items.items) {
          this.selected.push(item.id)
        }
      }
    },

    closeImg () {
      this.dialogImg = false
      this.currentImg = ''
    },
    openImg (url) {
      this.currentImg = url
      this.dialogImg = true
    },
    showHistory (changes) {
      this.history = changes
      this.dialogHistory = true
      console.log(changes)
    },
    getSiteLink (item) {
      let link = 'https://hetirenkaat.fi'
      switch (item.category) {
        case 1:
          link += '/product/'
          break
        case 2:
          link += '/product-rim/'
          break
        case 3:
          link += '/product-rim/'
          break
        case 4:
          link += '/product-sticker/'
          break
        case 5:
          link += '/product-cover/'
          break
        case 6:
          link += '/product-wcc/'
          break
        case 7:
          link += '/product-moto/'
          break
        case 8:
          link += '/product-spare/'
          break
        default:
          link += '/product/'
      }
      link += item.id
      return link
    },
    deleteImg (img) {
      if (img.src !== undefined && img.src !== '') {
        const temp = img.src.split('images')
        if (temp.length < 2) return
        let fname = temp[temp.length - 1]
        if (fname[0] === '/') {
          fname = fname.slice(1)
        }
        const body = {
          filename: fname
        }
        fetch(`https://${process.env.VUE_APP_BACKEND}/product/image/delete`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + window.btoa(`${this.$store.state.Auth.username}:${this.$store.state.Auth.password}`)
          },
          body: JSON.stringify(body)
        }).then(response => {
          if (response.status === 200) {
            this.$toast.success('Image deleted')
            for (let i = 0; i < this.editedItem.images.length; i++) {
              if (this.editedItem.images[i].src === img.src) {
                this.editedItem.images.splice(i, 1)
              }
            }
            this.$store.dispatch('Products/SAVE', this.editedItem)
              .then(response => {
                this.$toast.success('Product saved')
                this.close()
              })
              .catch(err => this.$toast.error(`Saving error: ${err}`))
          }
        }).catch(err => {
          this.$toast.error('Image delete: ', err)
          console.log(err)
        })
      } else {
        this.$toast.success('Image deleted')
        for (let i = 0; i < this.editedItem.images.length; i++) {
          if (this.editedItem.images[i].src === img.src) {
            this.editedItem.images.splice(i, 1)
          }
        }
      }
    },

    uploadFile () {
      if (this.files === null) return
      const formData = new FormData()
      formData.append('file', this.files)
      fetch(`https://${process.env.VUE_APP_BACKEND}/product/image`, {
        method: 'POST',
        headers: {
          Authorization: 'Basic ' + window.btoa(`${this.$store.state.Auth.username}:${this.$store.state.Auth.password}`)
        },
        body: formData
      }).then(response => {
        response.json().then(body => {
          this.$toast.success('Image uploaded')
          if (this.editedItem.images === undefined) {
            this.$set(this.editedItem, 'images', [])
          }
          this.editedItem.images.push({
            src: body.src
          })
          this.$store.dispatch('Products/SAVE', this.editedItem)
            .then(response => {
              this.$toast.success('Product saved')
              this.close()
            })
            .catch(err => this.$toast.error(`Saving error: ${err}`))
        })
      }).catch(err => {
        this.$toast.error('Image upload: ', err)
        console.log(err)
      })
    },

    clear () {
      this.warehouseFilter = null
      this.search = ''
      this.seasonFilter = []
      this.searchWidth = ''
      this.searchHeight = ''
      this.searchRadius = ''
      this.searchManufacturer = ''
      this.searchRimWidth = ''
      this.searchET = ''
      this.searchDIA = ''
      this.searchPCD = ''
      this.searchDiameter = ''
      this.searchIndex = ''
      this.searchMotoType = null
      this.searchMotoWidth = ''
      this.searchMotoHeight = ''
      this.searchMotoRadius = ''
      this.searchStickDiameter = ''
      this.searchCapSize = ''
      this.$forceUpdate()
    },

    trash () {
      this.$set(this, 'showDeleted', !this.showDeleted)
      this.$set(this, 'showSelect', !this.showSelect)
      this.$forceUpdate()
    },

    genArticle () {
      const uid = this.$store.state.Users.list.filter(u => u.name === this.$store.state.Auth.username)
      const rnd = (min, max) => Math.floor(Math.random() * (max - min)) + min
      return `${this.categoryFilter}${uid[0].id}${rnd(1000, 9999)}`
    },

    checkWarehouse (id) {
      return this.editedInvoice.from == null || this.editedInvoice.from === id
    },

    clearInvoice () {
      this.$set(this, 'tempForProduct', [])
      this.$set(this, 'editedInvoice', {
        number: Math.round(Math.random() * (100000 - 9) + 9),
        type: null,
        from: null,
        to: null,
        products: [],
        description: '',
        user: {},
        status: null
      })
      this.$set(this, 'newInvoice', true)
    },

    getProductCount (id) {
      const p = this.productsList.filter(i => i.id === id)
      return p.length > 0 ? p[0].stock_quantity : 0
    },

    checkInvoice (e) {
      this.editedInvoice.products.concat(this.tempForProduct)
      this.$set(this, 'newInvoice', false)
    },

    changeCategory (item) {
      if (this.editedItem.name === '') {
        this.$set(this.editedItem, 'attributes', [])
      }
      if (this.editedItem.attributes.length === 0) {
        const category = this.getCategories.filter(c => c.id === item)
        if (category.length > 0) {
          for (const ca of category[0].attributes) {
            for (const attr of this.getAttributes) {
              if (attr.id === ca) {
                this.editedItem.attributes.push({
                  id: ca,
                  value: ''
                })
              }
            }
          }
        }
      }
    },

    deleteProduct (item) {
      this.editedInvoice.products.splice(this.editedInvoice.products.indexOf(item), 1)
    },

    getProductName (id) {
      const list = this.productsList.filter(item => item.id === id)
      return list.length > 0 ? list[0].name : ''
    },

    getAttrName (id) {
      const list = this.getAttributes.filter(item => item.id === id)
      return list.length > 0 ? list[0].name : ''
    },

    getWarehouseName (id) {
      const list = this.getWarehouses.filter(item => item.id === id)
      return list.length > 0 ? list[0].name : ''
    },

    addInvoice (item) {
      this.dialogInvoice = true
      const p = {
        product: item.id,
        count: 1
      }
      this.tempForProduct.push(p)
      this.$set(this.editedInvoice, 'from', item.warehouse)
      this.editedInvoice.products.push(p)
    },

    addInvoiceConfirm () {
      console.log(this.editedInvoice)
      this.$set(this.editedInvoice, 'user', this.$store.state.Auth.role)
      if (this.newInvoice) {
        this.$store.dispatch('Invoices/NEW', this.editedInvoice)
      } else {
        this.$store.dispatch('Invoices/SAVE', this.editedInvoice)
      }
      this.dialogInvoice = false
    },

    deleteProductFromInvoice (item) {
      console.log(item)
    },

    getAttributeOptionsById (id) {
      const result = this.$store.state.Attributes.list.filter(attr => attr.id === id)
      return result[0].options
    },

    editItem (item) {
      this.editedIndex = this.$store.state.Products.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    setDeleted (item) {
      this.editedIndex = this.$store.state.Products.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.deleted = true
      this.$store.state.Products.list.splice(this.editedIndex, 1)
      this.save()
    },

    recoverDeleted (item) {
      this.editedIndex = this.$store.state.Products.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.deleted = false
      this.save()
    },

    deleteItem (item) {
      this.editedIndex = this.$store.state.Products.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.$store.state.Products.list.splice(this.editedIndex, 1)
      this.$store.dispatch('Products/DELETE', this.editedItem.id)
        .then(response => {
          this.$toast.success('Product deleted')
          this.closeDelete()
        })
        .catch(err => this.$toast.error(`Deleting error: ${err}`))
    },

    deleteMany () {
      this.$store.dispatch('Products/DELETE_MANY', this.selected)
        .then(response => {
          this.$toast.success('Product deleted')
          this.closeDelete()
        })
        .catch(err => this.$toast.error(`Deleting error: ${err}`))
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.$set(this.defaultItem, 'sku', this.genArticle())
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    itemRowBackground (item) {
      return item.deleted ? 'deletedItemBackground' : ''
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeHistory () {
      this.history = []
      this.dialogHistory = false
    },

    closeInvoice () {
      this.dialogInvoice = false
    },

    checkExist (sku) {
      if (this.productsList !== null) {
        const ok = this.productsList.find(p => p.sku === sku)
        if (ok) {
          return true
        }
        return false
      }
    },

    save () {
      this.editedItem.user = this.$store.state.Auth.username
      if (this.editedIndex > -1) {
        this.$store.dispatch('Products/SAVE', this.editedItem)
          .then(response => {
            this.$toast.success('Product saved')
            this.close()
          })
          .catch(err => this.$toast.error(`Saving error: ${err}`))
      } else {
        if (!this.checkExist(this.editedItem.sku)) {
          this.$store.dispatch('Products/NEW', this.editedItem)
            .then(response => {
              this.$toast.success('Product created')
              this.close()
            })
            .catch(err => this.$toast.error(`Creating error: ${err}`))
        } else {
          this.$toast.warning('Product with current article is exist, set new article or update existing product')
        }
      }
    },

    refresh () {
      this.$store.dispatch('Products/RELOAD')
        .then(response => {
          this.$toast.info('Data updated')
          this.$forceUpdate()
        })
        .catch(err => this.$toast.error(`Refresh error: ${err}`))
    }
  },
  created () {
    this.$store.dispatch('Users/LOAD')
    this.$store.dispatch('Categories/LOAD')
    this.$store.dispatch('Attributes/LOAD')
    this.$store.dispatch('Warehouses/LOAD')
    this.$store.dispatch('Invoices/LOAD')
    this.$store.dispatch('Products/LOAD').then((data) => {
      this.loadTable = false
    })
  },
  mounted () {
    this.categoryFilter = 1
    this.editedItem.sku = this.genArticle()
  },
  filters: {
    clearDate: function (dt) {
      const d = dt.split('T')
      const t = d[1].split('.')
      return `${d[0]} ${t[0]}`
    }
  }
}
</script>

<style lang="scss">
  .deletedItemBackground {
    color: red
  }
</style>
