import AXIOS from './util'

const state = {
  list: []
}

const mutations = {
  LIST (state, value) {
    state.list = value
  },
  SET (state, value) {
    state.list.filter(item => {
      if (item.id === value.id) {
        const i = state.list.indexOf(item)
        state.list[i] = value
      }
    })
  }
}

const actions = {
  INIT ({ state, commit, dispatch }) {
    dispatch('LOAD')
  },
  SET_LIST ({ state, commit }, value) {
    commit('LIST', value)
  },
  LOAD ({ state, commit, dispatch }) {
    AXIOS.get('/warehouses')
      .then((response) => {
        dispatch('SET_LIST', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  NEW ({ state, commit, dispatch }, value) {
    AXIOS.post('/warehouses', [value])
      .then((response) => {
        dispatch('LOAD')
      })
      .catch((error) => {
        console.log(error)
      })
  },
  SAVE ({ state, commit, dispatch }, value) {
    AXIOS.put('/warehouse', value)
      .then((response) => {
        dispatch('LOAD')
      })
      .catch((error) => {
        console.log(error)
      })
  },
  DELETE ({ state, commit, dispatch }, id) {
    AXIOS.delete(`/warehouse/${id}`)
      .then((response) => {
        dispatch('LOAD')
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
