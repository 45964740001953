<template>
  <v-container fluid grid-list-md>
    <v-layout>
      <v-flex md12>
        <v-data-table
          :headers="headers"
          :items="invoicesList"
          :search="search"
          :loading="loadTable"
          loading-text="Please wait, data loading..."
          sort-by="id"
          class="elevation-1"
        >
          <template v-slot:item.created="{ item }">
            {{ item.created | clearDateTime }}
          </template>
          <template v-slot:item.from="{ item }">
            {{ getWarehouseName(item.from) }}
          </template>
          <template v-slot:item.to="{ item }">
            {{ getWarehouseName(item.to) }}
          </template>
          <template v-slot:item.products="{ item }">
            <p class="mb-1" v-for="p of item.products" :key="p.product">
              {{ getProductName(p.product) + ' [ ' + p.count +' ]' }}
            </p>
          </template>
          <template v-slot:item.type="{ item }">
            {{ getName(item.type, types) }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip
              :color="getColor(item.status)"
              dark
            >
              {{ getName(item.status, statuses) }}
            </v-chip>
          </template>
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Invoices</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
               <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="700px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New invoice
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model.number="editedItem.number"
                            label="Invoice number"
                            required
                            :readonly="true"
                          ></v-text-field>
                        </v-col>
                         <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-model="editedItem.type"
                            :items="types"
                            item-text="name"
                            item-value="id"
                            label="Type"
                            :disabled="checkStatus"
                            required
                          ></v-select>
                        </v-col>
                         <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-model="editedItem.status"
                            :items="statuses"
                            item-text="name"
                            item-value="id"
                            label="Status"
                            :disabled="editedItem.status === 2"
                            required
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                         <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-select
                            v-model="editedItem.from"
                            :items="getWarehouses"
                            item-text="name"
                            item-value="id"
                            label="From"
                            :disabled="checkStatus"
                            required
                          ></v-select>
                        </v-col>
                         <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-select
                            v-model="editedItem.to"
                            :items="getWarehouses"
                            item-text="name"
                            item-value="id"
                            label="To"
                            :disabled="checkStatus || editedItem.type === 1"
                            required
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                         <v-col
                          cols="12"
                          sm="6"
                          md="12"
                        >
                          <v-text-field
                            v-model="editedItem.description"
                            :readonly="editedItem.status === 2"
                            label="Description"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="12"
                        >
                          <v-btn
                            @click="addProductDialog = true"
                            :disabled="checkStatus"
                          >
                            Add product
                          </v-btn>
                          <v-data-table v-if="editedItem.products.length > 0"
                            :headers="countHeaders"
                            :items="editedItem.products">
                            <template v-slot:item.product="{ item }">
                              {{ getProductName(item.product) }}
                            </template>
                            <template v-slot:item.count="{ item, index }">
                                <v-text-field
                                  type="number"
                                  v-model.number="editedItem.products[index].count"
                                  :max="getProductCount(item.product)"
                                  :min="1"
                                  :disabled="checkStatus"
                                />
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                small
                                @click="deleteProduct(item)"
                                :disabled="checkStatus"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                      :disabled="checkData"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Delete this invoice?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="addProductDialog" max-width="400px">
                <v-card>
                  <v-card-title>Add product to invoice</v-card-title>
                  <v-card-text>
                    <v-autocomplete
                      :items="getProductsByWarehouse"
                      item-text="name"
                      label="Enter product name"
                      item-value="id"
                      @change="checkProduct"
                      required
                    ></v-autocomplete>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeAdd">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="addProductToList">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mr-2"
                  @click="printItem(item)">
                  mdi-printer
                </v-icon>
              </template>
              <span>Print invoice</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mr-2"
                  @click="editItem(item)">
                  mdi-pencil
                  </v-icon>
              </template>
              <span>Edit invoice</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click="deleteItem(item)">
                mdi-delete
              </v-icon>
              </template>
              <span>Delete invoice</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'invoices',
  components: {

  },
  computed: {
    invoicesList () {
      return this.$store.state.Invoices.list === null ? [] : this.$store.state.Invoices.list
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New invoice' : 'Edit invoice'
    },
    getWarehouses () {
      return this.$store.state.Warehouses.list === null ? [] : this.$store.state.Warehouses.list
    },
    getProducts () {
      return this.$store.state.Products.list === null ? [] : this.$store.state.Products.list
    },
    getProductsByWarehouse () {
      return this.getProducts.filter(i => i.warehouse === this.editedItem.from)
    },
    checkProductsCount () {
      return this.editedItem.products.length === 0
    },
    checkStatus () {
      return this.editedItem.status !== 0
    },
    checkData () {
      return this.editedItem.type === null || this.editedItem.status === null || this.editedItem.products.length === 0 || this.editedItem.from === null
    }
  },
  data () {
    return {
      dialog: false,
      dialogDelete: false,
      search: '',
      loadTable: true,
      types: this.$INVOICE_TYPES,
      statuses: this.$INVOICE_STATUS,
      headers: [
        { text: 'Number', value: 'number', sortable: true },
        { text: 'Type', value: 'type', sortable: true },
        { text: 'From', value: 'from', sortable: true },
        { text: 'To', value: 'to', sortable: true },
        { text: 'Products [count]', value: 'products', sortable: false },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Created', value: 'created', sortable: true },
        { text: 'Status', value: 'status', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      countHeaders: [
        { text: 'Name', value: 'product', width: '70%', sortable: true },
        { text: 'Count', value: 'count', width: '20%' },
        { text: 'Actions', value: 'actions', width: '10%' }
      ],
      editedIndex: -1,
      editedItem: {
        number: Math.round(Math.random() * (100000 - 9) + 9),
        type: null,
        from: null,
        to: null,
        products: [],
        description: '',
        user: null,
        status: null
      },
      defaultItem: {
        number: Math.round(Math.random() * (100000 - 9) + 9),
        type: null,
        from: null,
        to: null,
        products: [],
        description: '',
        user: null,
        status: null
      },
      addProductDialog: false,
      selectedProduct: null
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  created () {

  },
  methods: {
    getProductCount (id) {
      const p = this.getProducts.filter(i => i.warehouse === this.editedItem.from && i.id === id)
      return p.length > 0 ? p[0].stock_quantity : 0
    },

    getWarehouseName (id) {
      const list = this.getWarehouses.filter(w => w.id === id)
      return list.length > 0 ? list[0].name : ''
    },

    getProductName (id) {
      const list = this.getProducts.filter(p => p.id === id)
      return list.length > 0 ? list[0].name : ''
    },

    closeAdd () {
      this.addProductDialog = false
    },

    addProductToList () {
      this.editedItem.products.push(this.selectedProduct)
      this.addProductDialog = false
      this.$set(this, 'selectedProduct', null)
    },

    checkProduct (e) {
      this.$set(this, 'selectedProduct', {
        product: e,
        count: 1
      })
    },

    deleteProduct (item) {
      this.editedItem.products.splice(this.editedItem.products.indexOf(item), 1)
    },

    getName (item, arr) {
      const obj = arr.find(i => i.id === item)
      return obj.name
    },

    getColor (status) {
      const obj = this.statuses.find(i => i.id === status)
      return obj.color
    },

    editItem (item) {
      this.editedIndex = this.$store.state.Invoices.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.$store.state.Invoices.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.$store.state.Invoices.list.splice(this.editedIndex, 1)
      this.$store.dispatch('Invoices/DELETE', this.editedItem.id)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      this.$set(this.editedItem, 'user', this.$store.state.Auth.role)
      console.log(this.editedItem)
      if (this.editedIndex > -1) {
        this.$store.dispatch('Invoices/SAVE', this.editedItem)
      } else {
        let uniqNum = false
        while (!uniqNum) {
          const list = this.invoicesList.filter(item => item.number === this.editedItem.number)
          if (list.length === 0) {
            uniqNum = true
          } else {
            this.editedItem.number = Math.round(Math.random() * (100000 - 9) + 9)
          }
        }
        this.$store.dispatch('Invoices/NEW', this.editedItem)
      }
      this.$store.dispatch('Products/RELOAD')
      this.close()
    },

    printItem (item) {
      this.$store.dispatch('Invoices/PRINT', item.id)
    }
  },
  mounted () {
    this.$store.dispatch('Invoices/LOAD').then((data) => {
      this.loadTable = false
    })
  },
  filters: {
    clearDateTime: function (str) {
      if (str !== undefined || str !== '') {
        const d = str.split('T')
        const t = d[1].split('.')
        return d[0] + ' ' + t[0]
      }
    }
  }
}
</script>

<style lang="scss">

</style>
